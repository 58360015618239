import React, { useContext, useState, useEffect } from "react";
import {
  getPDF,
  refundPayment,
  updateRefundPayment,
} from "../../../api/student";
import { StudentContext } from "../../../context/StudentContext";
import toast from "react-hot-toast";
import { CiSaveDown2 } from "react-icons/ci";
import { Tooltip } from "antd";
import { FaFilePdf } from "react-icons/fa6";
import { Spin } from "antd";
import { FaFileUpload } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

const Refund = ({}) => {
  const studentContext = useContext(StudentContext);
  const { inEditStudent, refundPayment: fetchedRefundPayment } = studentContext;

  const [isRowVisible, setIsRowVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRowLocked, setIsRowLocked] = useState(false);
  const name = localStorage.getItem("name");
  const [rowData, setRowData] = useState({
    email: inEditStudent.email,
    refundAbleAmount: "",
    paymentStatus: "",
    amount: "",
    reason: "",
    ttCopy: null,
    bank: "",
  });

  const addRow = () => {
    setIsRowVisible(true);
  };

  const handleInputChange = (field, value) => {
    setRowData((prevRowData) => ({ ...prevRowData, [field]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRowData((prevRowData) => ({ ...prevRowData, ttCopy: file }));
  };

  const sendForApproval = async () => {
    if (!rowData.amount) {
      toast.error("Please enter amount");
      return;
    }

    if (!rowData.reason) {
      toast.error("Please provide the reason for returning the amount.");
      return;
    }
    try {
      let email = await inEditStudent.email;
      if (email) {
        const data = await refundPayment(inEditStudent.id, {
          ...rowData,
          email: email,
          paymentType: "Tution_Fee",
          paymentStatus: "Send For Approval",
        });
        if (data) {
          toast.success("Approval has been sent to the payment department.");
          setRowData({
            ...rowData,
            paymentStatus: "Send For Approval",
          });

          setIsRowLocked(true);
        }
      }
    } catch (error) {
      console.error("Error sending for approval:", error);
    }
    setIsRowLocked(true);
  };

  const saveRow = async () => {
    if (!rowData.amount) {
      toast.error("Please enter amount");
      return;
    }

    if (!rowData.reason) {
      toast.error("Please provide the reason for returning the amount.");
      return;
    }

    if (!rowData.refundAbleAmount) {
      toast.error("Please enter Refundable Amount");
      return;
    }
    if (!rowData.bank) {
      toast.error("Please select bank");
      return;
    }
    try {
      const obj = {
        paymentType: "Tution_Fee",
        paymentStatus: "Approved",
        email: inEditStudent.email,
        ...rowData,
      };
      const data = await updateRefundPayment(inEditStudent.id, obj);
      if (data) {
        toast.success("Successfully approved");
        setRowData({
          ...rowData,
          paymentStatus: "Approved",
        });

        setIsRowLocked(true);
      }
    } catch (error) {
      console.error("Error sending for approval:", error);
    }
    setIsRowLocked(true);
  };

  const editRow = () => {
    setIsRowLocked(false);
  };

  const openPdf = async () => {
    if (rowData.ttCopyName === "") {
      toast.error("Please upload the PDF first");
      return;
    }
    setLoading(true);
    const data = await getPDF({
      encryptedUrl: fetchedRefundPayment.transactions[0].refund_ttcopy,
      type: "Tution_Fee",
    });

    if (data.success) {
      window.open(data.url, "_blank");
    } else {
      toast.error(data.msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Check if fetchedRefundPayment is defined and has transactions
    if (fetchedRefundPayment && fetchedRefundPayment.transactions.length > 0) {
      // Find the transaction with paymentType "Application_Fee"
      const applicationFeeTransaction = fetchedRefundPayment.transactions.find(
        (transaction) => transaction.paymentType === "Tution_Fee"
      );

      if (applicationFeeTransaction) {
        const {
          paymentType,
          amount,
          reason,
          refund_ttcopy,
          refundAbleAmount,
          status,
          bank,
        } = applicationFeeTransaction;

        // Update state with the found transaction details
        setRowData({
          email: inEditStudent.email,
          refundAbleAmount,
          amount,
          reason,
          ttCopy: refund_ttcopy,
          paymentStatus: status,
          bank: bank,
        });
        setIsRowVisible(true);
        setIsRowLocked(true);
      } else {
        // Handle case where there is no "Application_Fee" transaction
        setRowData({
          email: inEditStudent.email,
          refundAbleAmount: "",
          amount: "",
          reason: "",
          ttCopy: "",
          paymentStatus: "",
          bank: "",
        });
        setIsRowVisible(false);
        setIsRowLocked(false);
      }
    }
  }, [fetchedRefundPayment]);

  return (
    <div className="container mx-auto p-4">
      {!isRowVisible && (
        <button
          onClick={addRow}
          className="bg-green-500 text-white px-4 py-2 rounded my-4 "
        >
          Add Row
        </button>
      )}
      <table className="table-auto w-full border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Amount (€)</th>
            <th className="border border-gray-300 px-4 py-2">Bank</th>
            <th className="border border-gray-300 px-4 py-2">
              Reason for refund
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Send for Approval
            </th>

            <th className="border border-gray-300 px-4 py-2">
              Refundable Amount (€)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Refundable ttCopy
            </th>
            <th className="border border-gray-300 px-4 py-2">View ttCopy</th>
            <th className="border border-gray-300 px-4 py-2">Save</th>
            <th className="border border-gray-300 px-4 py-2">Payment Status</th>
            <th className="border border-gray-300 px-4 py-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {isRowVisible && (
            <tr>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <input
                  type="number"
                  value={rowData.amount}
                  onChange={(e) => handleInputChange("amount", e.target.value)}
                  className="w-full px-2 py-1 border rounded"
                  disabled={isRowLocked}
                />
              </td>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <div className="flex flex-col items-center">
                  <div className="grid grid-cols-1 justify-center">
                    <select
                      name="bank"
                      placeholder="Bank"
                      value={rowData.bank}
                      onChange={(e) =>
                        handleInputChange("bank", e.target.value)
                      }
                      className="border rounded-md border-gray-300 p-1.5 w-full focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out transform hover:shadow-md"
                    >
                      <option value="">Select Bank</option>
                      <option value="LIGMR FRANCE - BNP PARIBAS">
                        LIGMR FRANCE - BNP PARIBAS
                      </option>
                      <option value="LIGMR STRIPE">LIGMR STRIPE</option>
                      <option value="LIGMR RAZORPAY">LIGMR RAZORPAY</option>
                      <option value="QONTO - LIGMR">QONTO - LIGMR</option>
                      <option value="LIGMR KOTAK">LIGMR KOTAK</option>
                      <option value="STRIPE - ANKIT SONI A/C">
                        STRIPE - ANKIT SONI A/C
                      </option>
                      <option value="ESPL RAZORPAY">ESPL RAZORPAY</option>
                      <option value="ESPL ICICI">ESPL ICICI</option>
                      <option value="CASH">CASH</option>
                      <option value="STRIPE - LIGMR">STRIPE - LIGMR</option>
                    </select>
                  </div>
                </div>
              </td>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <Tooltip title={rowData.reason}>
                  <input
                    type="text"
                    value={rowData.reason}
                    onChange={(e) =>
                      handleInputChange("reason", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded"
                    // disabled={isRowLocked}
                    readOnly={isRowLocked}
                  />
                </Tooltip>
              </td>

              <td className="border font-semibold border-gray-300 px-4 py-2">
                <button
                  onClick={sendForApproval}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  disabled={isRowLocked}
                >
                  Send for Approval
                </button>
              </td>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <input
                  type="number"
                  value={rowData.refundAbleAmount}
                  onChange={(e) =>
                    handleInputChange("refundAbleAmount", e.target.value)
                  }
                  className="w-full px-2 py-1 border rounded"
                  disabled={isRowLocked}
                />
              </td>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <input
                  type="file"
                  accept=".pdf,.jpg,.jpeg"
                  onChange={handleFileChange}
                  className="w-full px-2 py-1 border rounded"
                  disabled={isRowLocked}
                />
              </td>
              <td className="border font-semibold border-gray-300 px-4 py-2">
                <div className="flex flex-col items-center">
                  <button
                    type="button"
                    className={`bg-white-500 rounded-md font-semibold hover:bg-primary-dark focus:border-primary`}
                    onClick={() => {
                      openPdf();
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : fetchedRefundPayment?.transactions[0]?.refund_ttcopy ? (
                      <FaFilePdf className="text-green-500 text-xl " />
                    ) : (
                      <FaFileUpload className="text-red-500 text-xl" />
                    )}
                  </button>
                </div>
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={saveRow}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                  disabled={isRowLocked}
                >
                  Save
                </button>
              </td>
              <td
                className={`border font-semibold text-center text-base border-gray-300 px-4 py-2    
              ${
                rowData.paymentStatus === "Send For Approval"
                  ? "text-red-600"
                  : `${
                      rowData.paymentStatus === "Approved"
                        ? "text-green-600"
                        : ""
                    }`
              }`}
              >
                {rowData.paymentStatus}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={editRow}
                  className="bg-yellow-500 text-white px-4 py-2 rounded"
                >
                  Edit
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Refund;
