import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const BTSComponent = ({ formState, handleInputChange }) => {
  // Dummy data for BTS program and degree options
  const btsProgramOptions = [
    {
      label: "BTS Communication & Digital Marketing",
      value: "btsDigitalMarketing",
    },
    {
      label: "BTS Small and Medium Entreprise Management",
      value: "btsSmeManagement",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-2">
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="diploma-label">BTS</InputLabel>
          <Select
            labelId="bts-label"
            id="bts"
            name="bts"
            value={formState.bts}
            onChange={handleInputChange}
            label="btsDegree"
          >
            {btsProgramOptions.map((program) => (
              <MenuItem key={program.value} value={program.value}>
                {program.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
};

export default BTSComponent;
