import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
const MBAComponent = ({ formState, handleInputChange }) => {
  const mbaProgramOptions = [
    {
      label: "MBA International Management & Entrepreneurship",
      value: "mbaEntrepreneurship",
    },
    {
      label: "MBA  International Marketing & Communication",
      value: "mbaDigitalMarketing",
    },
    { label: "MBA International Finance", value: "mbaFinance" },
    { label: "MBA Business Logistics & Operation Management", value: "mbaSupplyChain" },
  ];

  const mbaDegreeOptions = {
    mbaEntrepreneurship: ["MBA 1st Year (Bac+4)", "MBA 2nd Year (Bac+5)"],
    mbaDigitalMarketing: ["MBA 1st Year (Bac+4)", "MBA 2nd Year (Bac+5)"],
    mbaFinance: ["MBA 1st Year (Bac+4)", "MBA 2nd Year (Bac+5)"],
    mbaSupplyChain: ["MBA 1st Year (Bac+4)", "MBA 2nd Year (Bac+5)"],
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-4">
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="diploma-label">MBA</InputLabel>
          <Select
            labelId="mba-label"
            id="mba"
            name="mba"
            value={formState.mba}
            onChange={handleInputChange}
            label="mbaDegree"
          >
            {mbaProgramOptions.map((program) => (
              <MenuItem key={program.value} value={program.value}>
                {program.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="diploma-label">MBA Degree</InputLabel>
          <Select
            labelId="mbadegree-label"
            id="mbaDegree"
            name="mbaDegree"
            value={formState.mbaDegree}
            onChange={handleInputChange}
            label="mbaDegree"
          >
            {mbaDegreeOptions[
              formState.mba !== "" ? formState.mba : "mbaEntrepreneurship"
            ]?.map((degree) => (
              <MenuItem key={degree} value={degree}>
                {degree}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
};

export default MBAComponent;
