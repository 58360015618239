import CryptoJS from "crypto-js";

const secretKey = "YourSecretKey"; // Replace with a strong secret key

export const encryptRole = (role) => {
  const encryptedRole = CryptoJS.AES.encrypt(role, secretKey).toString();
  return encryptedRole;
};

export const decryptRole = (encryptedRole) => {
  const decryptedRole = CryptoJS.AES.decrypt(encryptedRole, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  return decryptedRole;
};
